<ng-container *ngIf='!loading, else isLoading' class="mt--lg">
  <ng-container *ngIf="!loading && paginatedUbillableShipments.length > 0, else noUnbillableShipment">
    <mat-card class='mt--xs mat-elevation-z0 mb--sm'>
      <mat-card-content>
        <div class='responsive-table'>
          <table mat-table [dataSource]="paginatedUbillableShipments" multiTemplateDataRows>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef style='width: 10%'>Date</th>
              <td mat-cell *matCellDef="let element" class='text--sm'>
                {{ element.createdAt | date: 'E, d MMM' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="unknownType">
              <th mat-header-cell *matHeaderCellDef style='width: 15%'>Unknown Type</th>
              <td mat-cell *matCellDef="let element" class='text--sm'>
                <div class='pill-box pill-status' [ngClass]="getBadgeClass(element?.data?.providerInvoiceItem)">{{ getStatusText(element?.data?.providerInvoiceItem) }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="provider">
              <th mat-header-cell *matHeaderCellDef style='width: 15%'>Provider</th>
              <td mat-cell *matCellDef="let element" class='text--sm'>
                <div *ngIf="!isInsuranceProvider(element.provider)">
                    <img
                      [src]="getProviderImage(element.provider)"
                      width="25px"
                      height="25px"
                      [mtxTooltip]="getProviderName(element.provider)"
                      mtxTooltipPosition="above"
                      mtxTooltipClass="mtx-recon-tooltip"/>
                </div>
                <div *ngIf="isInsuranceProvider(element.provider)">
                  <mat-icon class='icon-info mat-icon-sm'
                  [mtxTooltip]="element.provider"
                  mtxTooltipPosition="above"
                  mtxTooltipClass="mtx-recon-tooltip"></mat-icon>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="trackingNumber">
              <th mat-header-cell *matHeaderCellDef style='width: 25%'>Tracking</th>
              <td mat-cell *matCellDef="let element" class='text--sm cursor-pointer' (click)="copyText(element.trackingNumber)">
                {{ element.trackingNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef style='width: 25%'>Reference</th>
              <td mat-cell *matCellDef="let element" class='text--sm'>
                <a class='text--primary-green cursor-pointer' (click)="openEdiInvoiceDrawer(element)">{{ getTrackingReference(element, 'DO NOT BILL RECON ADJ') }}</a>
              </td>
            </ng-container>
            <ng-container matColumnDef="cost">
              <th mat-header-cell *matHeaderCellDef style='width: 10%'>Cost</th>
              <td mat-cell *matCellDef="let element" class='text--sm'>
                {{ element.totalAmount | currency : 'CAD' : 'symbol-narrow' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
          </table>
        </div>

        <mat-paginator
        (page)="pageChanged($event)"
        [length]='unbillableShipmentsCount'
        [pageSize]="itemsPerPage"
        [pageSizeOptions]="itemsPerPageOptions"
        [pageIndex]='currentPage'>
      </mat-paginator>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <ng-template #noUnbillableShipment>
    <div class='row justify-content-center'>
      <div class='column--12 align-center'>
        <div style='width: 220px; margin: 0 auto;'>
          <img src='{{ CDN_URL }}/admin/images/empty-history_animated.svg' />
          <p>No Unbillable Shipments available.</p>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #isLoading>
<div class='align-center mt--lg'>
  <app-loading></app-loading>
</div>
</ng-template>