import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, CompanyWalletTransaction } from '@app/pages/authenticated/pages/users/users.model';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { Router } from '@angular/router';
import { BillingTypes, DateFormats, DefaultTimezone, PanelWidths } from '@app/shared/constants';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { AddTransactionComponent } from '@app/pages/authenticated/pages/users/settings/components/wallet-transactions/add-transaction/add-transaction.component';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../../../../../../environments/environment';
import { DateTime } from 'luxon';
import { Sort, SortDirection } from '@angular/material/sort';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

export interface WalletTransactionQueryDto {
  start_date: string;
  end_date: string;
  items_per_page: number;
  page: number;
  stripe_only: boolean;
  order_by_value: string;
  order_by_direction: string;
}

@Component({
  selector: 'app-wallet-transactions',
  templateUrl: './wallet-transactions.component.html',
})
export class WalletTransactionsComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading = true;
  public currentBalance: number = 0;
  public canEdit: boolean = false;
  public DATE_YEAR: string = DateFormats.DATE_YEAR_SML;
  public transactions: CompanyWalletTransaction[] = [];
  public currentPage: number = 0;
  public itemsPerPage: number = 10;
  public orderByValue = 'created_at';
  public sortDescending: SortDirection = 'desc';
  public totalItems: number;
  public displayedColumns: string[] = ['created_at', 'description', 'amount'];
  public CDN_URL: string = environment.CDN_URL;
  public defaultTimezone = DefaultTimezone;
  public dateRange = {
    start: DateTime.now().setZone(this.defaultTimezone).minus({ month: 1 }).toJSDate(),
    end: DateTime.now()
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toJSDate(),
  };

  public showOnlyStripeTransaction: boolean = false;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private router: Router,
    private drawer: MtxDrawer,
    private bannerNotificationsService: BannerNotificationsService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.loading = true;
    if (this.company && this.user && this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_WALLET_TRANSACTIONS])) {
      const billingType = this.companyService.getBillingType(this.company);
      this.canEdit =
        billingType === BillingTypes.CREDIT_CARD &&
        this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_WALLET_TRANSACTIONS]);
      this.currentBalance = this.company.walletBalance;
      this.getTransactions();
    } else {
      this.router.navigate(['users']);
    }
  }

  public toggleStripeTransactions(checked: boolean) {
    this.showOnlyStripeTransaction = checked;
    this.currentPage = 0;
    this.getTransactions();
  }

  public selectDate(startDateInput: HTMLInputElement, endDateInput: HTMLInputElement): void {
    const startDateValue = startDateInput.value;
    const endDateValue = endDateInput.value;

    if (!startDateValue || !endDateValue) {
      return;
    }

    const startDate = DateTime.fromFormat(startDateValue, 'M/d/yyyy').startOf('day').toJSDate();
    const endDate = DateTime.fromFormat(endDateValue, 'M/d/yyyy').endOf('day').toJSDate();

    this.dateRange = {
      start: startDate,
      end: endDate,
    };
    this.currentPage = 0;
    this.getTransactions();
  }

  public pageChanged(pageEvent: PageEvent): void {
    if (pageEvent.pageIndex !== this.currentPage) {
      this.currentPage = pageEvent.pageIndex;
      this.getTransactions();
    }
  }

  public addFunds(): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(AddTransactionComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company: this.company },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.refreshCompany.emit();
          this.getTransactions();
          this.bannerNotificationsService.success('Adjustment was saved');
        }
      });
    }
  }

  public getReceipt(receiptUrl: string) {
    if (!receiptUrl) return;

    window.open(
      receiptUrl,
      'targetWindow',
      `
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=700,
      height=900`
    );
  }

  private getTransactions(): void {
    this.loading = true;
    this.transactions = [];

    const startDate = DateTime.fromJSDate(this.dateRange.start).setZone(this.defaultTimezone).startOf('day');
    const endDate = DateTime.fromJSDate(this.dateRange.end)
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

    const query: WalletTransactionQueryDto = {
      start_date: startDate.toISO(),
      end_date: endDate.toISO(),
      items_per_page: this.itemsPerPage,
      page: this.currentPage + 1,
      stripe_only: this.showOnlyStripeTransaction,
      order_by_value: this.orderByValue,
      order_by_direction: this.sortDescending.toUpperCase(),
    };

    this.companyService.getWalletTransactions(this.company.id, query).subscribe(
      (res: { walletTransactions: CompanyWalletTransaction[]; walletTransactionsCount: number }) => {
        this.logger.log('GET wallet transactions', res);
        this.transactions = res.walletTransactions;
        this.totalItems = res.walletTransactionsCount;
        this.loading = false;
      },
      (error) => {
        this.logger.error('Error GET Wallet Transactions', error);
        this.loading = false;
      }
    );
  }

  public setOrder(order: Sort): void {
    this.sortDescending = order.active === this.orderByValue ? (order.direction === 'desc' ? 'desc' : 'asc') : 'asc';
    this.orderByValue = order.active;
    this.currentPage = 0;
    this.getTransactions();
  }
}
