import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import { AdminUser } from '@app/shared/services/user.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import { ReportsService } from '@app/shared/services/reports.service';
import { Router } from '@angular/router';
import {
  AccountTypes,
  AccountTypesVerbose,
  DEFAULT_LOCALE,
  ProvinceList,
  ReportCardGroup,
  ShippingProviders,
} from '@app/shared/constants';
import { DateTime } from 'luxon';
import { formatNumber } from '@angular/common';
import * as _ from 'lodash';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-machool-reports',
  templateUrl: './machool-reports.component.html',
})
export class MachoolReportsComponent implements OnInit {
  @Input() user: AdminUser;
  @Input() canViewMachoolReports: boolean = false;
  @Input() canViewCards: boolean = false;
  @Input() canDownloadCardData: boolean = false;
  @Input() canViewTopRevenue: boolean = false;

  public loading: boolean = true;
  public filterForm: FormGroup | undefined;
  public ReportCardGroupTypes = ReportCardGroup;

  public readonly provincesDropdown = ProvinceList;

  public readonly couriersDropdown = [];

  public readonly accountTypeDropdown = [
    { id: AccountTypes.BYOA, text: AccountTypesVerbose.BYOA },
    { id: AccountTypes.MACHOOL, text: AccountTypesVerbose.MACHOOL },
  ];

  public daterange = {
    start: DateTime.now().minus({ day: 7 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(this.daterange.start),
    end: new FormControl<Date | null>(this.daterange.end),
  });

  public downloadingReportData: boolean = false;

  // REVENUE CARDS
  // Accounts
  public loadingAccountsCard = false;
  public loadingAccountsCardFailed = false;
  public totalAccounts: string;
  public accountsWithOne: string;

  // New Accounts
  public loadingNewAccountsCard = false;
  public loadingNewAccountsCardFailed = false;
  public totalNewAccounts: string;

  // Started Shipping
  public loadingStartedShippingCard = false;
  public loadingStartedShippingCardFailed = false;
  public totalStartedShipping: string;

  // Revenue
  public loadingRevenueCard = false;
  public loadingRevenueCardFailed = false;
  public totalRevenue: string;
  public maRevenue: string;
  public byoaRevenue: string;

  // Revenue By Charge
  public loadingChargedRevenueCard = false;
  public loadingChargedRevenueCardFailed = false;
  public totalChargedRevenue: string;
  public maChargedRevenue: string;
  public byoaChargedRevenue: string;

  // Green Shipments / Revenue
  public loadingGreenShipmentsCard = false;
  public loadingGreenShipmentsCardFailed = false;
  public greenRevenue: string;
  public greenShipments: string;

  // Stripe
  public loadingStripeCard = false;
  public loadingStripeCardFailed = false;
  public stripeRevenue: string;

  // Recon Adjustments
  public loadingReconAdjustmentsCard = false;
  public loadingReconAdjustmentsCardFailed = false;
  public reconAdjustment: string;
  public stripeReconAdjustment: string;
  public maReconAdjustment: string;

  // CQCD Revenue
  public loadingCQCDRevenueCard = false;
  public loadingCQCDRevenueCardFailed = false;
  public totalCQCDRevenue: string;
  public maCQCDRevenue: string;
  public byoaCQCDRevenue: string;

  // Panierdachat Revenue
  public loadingPDCRevenueCard = false;
  public loadingPDCRevenueCardFailed = false;
  public totalPDCRevenue: string;
  public maPDCRevenue: string;
  public byoaPDCRevenue: string;

  // Machool Wallet Balance
  public loadingWalletBalanceCard = false;
  public loadingWalletBalanceCardFailed = false;
  public totalWalletBalance: string = '0';
  public totalWalletAccounts: string = '0';

  // SHIPMENTS CARDS
  // Shipments - ALL
  public loadingAllShipmentsCard = false;
  public loadingAllShipmentsCardFailed = false;
  public totalShipments: string;
  public byoaShipments: string;
  public maShipments: string;

  // Shipments - MANUAL
  public loadingManualShipmentsCard = false;
  public loadingManualShipmentsCardFailed = false;
  public totalManualShipments: string;
  public manualByoaShipments: string;
  public manualMaShipments: string;

  // Shipments - E-COM
  public loadingEcommShipmentsCard = false;
  public loadingEcommShipmentsCardFailed = false;
  public totalEcommerceShipments: string;
  public ecommerceByoaShipments: string;
  public ecommerceMaShipments: string;

  // Shipments - API
  public loadingApiShipmentsCard = false;
  public loadingApiShipmentsCardFailed = false;
  public totalApiShipments: string;
  public apiByoaShipments: string;
  public apiMaShipments: string;

  // Shipments - CONNECT
  public loadingConnectShipmentsCard = false;
  public loadingConnectShipmentsCardFailed = false;
  public totalConnectShipments: string;
  public connectByoaShipments: string;
  public connectMaShipments: string;

  // TOP REVENUE
  // List
  public loadingTopRevenue = false;
  public loadingTopRevenueFailed = false;
  public hasMoreAccounts: boolean = false;
  public initialAccountListLength: number = 10;
  public revenueAccountsToList: any[];
  public topRevenueAccounts: any[];
  public topRevenueAccountsEmpty: boolean = false;
  public columnsToDisplay = ['index', 'companyName', 'total', 'revenue', 'sales_rep'];

  constructor(
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private sessionStorageService: SessionStorageService,
    private providersService: ProvidersService,
    private reportsService: ReportsService,
    private router: Router,
    private bannerNotificationsService: BannerNotificationsService
  ) {}
  ngOnInit() {
    this.loading = true;
    if (this.canViewMachoolReports) {
      if (this.canViewCards) {
        this.createForm();
        this.getAllInformation();
      }

      if (this.canViewTopRevenue) {
        this.getTopRevenueList();
      }

      this.loading = false;
    }
  }

  // fetch all the data for the cards
  public getAllInformation(): void {
    if (this.canViewCards) {
      if (!this.isAnyCardLoading()) {
        // Revenue
        this.refreshAccountsCard();
        this.refreshNewAccountsCard();
        this.refreshStartedShippingCard();
        this.refreshRevenueCard();
        this.refreshChargedRevenueCard();
        this.refreshGreenShipmentsCard();
        this.refreshStripeCard();
        this.refreshReconAdjustmentsCard();
        this.refreshCQCDRevenueCard();
        this.refreshPDCRevenueCard();
        this.refreshWalletBalanceCard();

        // Shipments
        this.refreshAllShipmentsCard();
        this.refreshManualShipmentsCard();
        this.refreshEcommShipmentsCard();
        this.refreshApiShipmentsCard();
        this.refreshConnectShipmentsCard();
      }
    }
    if (this.canViewTopRevenue) {
      this.getTopRevenueList();
    }
  }

  public isAnyCardLoading(): boolean {
    return (
      this.loadingAccountsCard ||
      this.loadingNewAccountsCard ||
      this.loadingStartedShippingCard ||
      this.loadingRevenueCard ||
      this.loadingChargedRevenueCard ||
      this.loadingGreenShipmentsCard ||
      this.loadingStripeCard ||
      this.loadingReconAdjustmentsCard ||
      this.loadingCQCDRevenueCard ||
      this.loadingPDCRevenueCard ||
      this.loadingWalletBalanceCard ||
      this.loadingAllShipmentsCard ||
      this.loadingManualShipmentsCard ||
      this.loadingEcommShipmentsCard ||
      this.loadingApiShipmentsCard ||
      this.loadingConnectShipmentsCard
    );
  }

  // TOP REVENUE EARNERS
  public getTopRevenueList(): void {
    this.loadingTopRevenue = true;
    this.loadingTopRevenueFailed = false;
    let query: any = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.TOP_REVENUE;
    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.topRevenueAccounts = res.accounts;
        this.revenueAccountsToList = this.topRevenueAccounts.slice(0, this.initialAccountListLength);
        this.hasMoreAccounts = this.topRevenueAccounts.length > this.initialAccountListLength;
        this.topRevenueAccountsEmpty = this.topRevenueAccounts.length === 0;
        this.loadingTopRevenue = false;
      },
      (err: Error) => {
        this.loadingTopRevenue = false;
        this.loadingTopRevenueFailed = true;
        this.logger.error('GET top accounts report error', err);
      }
    );
  }

  public showHideAccounts(): void {
    this.hasMoreAccounts = !this.hasMoreAccounts;
    if (this.hasMoreAccounts) {
      this.revenueAccountsToList = this.topRevenueAccounts.slice(0, this.initialAccountListLength);
    } else {
      this.revenueAccountsToList = this.topRevenueAccounts;
    }
  }

  // DOWNLOAD CARD DATA
  public downloadCardData(cardType: ReportCardGroup): void {
    if (this.canDownloadCardData) {
      this.downloadingReportData = true;
      const query: any = this.getFilterQuery();
      query.report_card_group = cardType;
      this.reportsService.getSummaryCardData(query).subscribe(
        (res) => {
          this.bannerNotificationsService.success("Report is generating, Slack will be notified once it's ready.");
          this.downloadingReportData = false;
        },
        (err: Error) => {
          this.downloadingReportData = false;
          this.logger.error('GET accounts report data error', err);
          this.bannerNotificationsService.error(err.message);
        }
      );
    }
  }

  // REVENUE CARDS
  public refreshAccountsCard(): void {
    this.loadingAccountsCard = true;
    this.loadingAccountsCardFailed = false;
    let query: any = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.ACCOUNTS;
    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalAccounts = formatNumber(res.totalAccounts, DEFAULT_LOCALE);
        this.accountsWithOne = formatNumber(res.accountsWithOne, DEFAULT_LOCALE);
        this.loadingAccountsCard = false;
      },
      (err: Error) => {
        this.loadingAccountsCard = false;
        this.loadingAccountsCardFailed = true;
        this.logger.error('GET accounts report error', err);
      }
    );
  }

  public refreshNewAccountsCard(): void {
    this.loadingNewAccountsCard = true;
    this.loadingNewAccountsCardFailed = false;
    let query: any = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.NEW_ACCOUNTS;
    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalNewAccounts = formatNumber(res.totalAccounts, DEFAULT_LOCALE);
        this.loadingNewAccountsCard = false;
      },
      (err: Error) => {
        this.loadingNewAccountsCard = false;
        this.loadingNewAccountsCardFailed = true;
        this.logger.error('GET accounts report error', err);
      }
    );
  }

  public refreshStartedShippingCard(): void {
    this.loadingStartedShippingCard = true;
    this.loadingStartedShippingCardFailed = false;
    let query: any = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.STARTED_SHIPPING;
    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalStartedShipping = formatNumber(res.totalAccounts, DEFAULT_LOCALE);
        this.loadingStartedShippingCard = false;
      },
      (err: Error) => {
        this.loadingStartedShippingCard = false;
        this.loadingStartedShippingCardFailed = true;
        this.logger.error('GET accounts report error', err);
      }
    );
  }

  public refreshRevenueCard(): void {
    this.loadingRevenueCard = true;
    this.loadingRevenueCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.REVENUE;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalRevenue = res.totalRevenue;
        this.maRevenue = res.maRevenue;
        this.byoaRevenue = res.byoaRevenue;
        this.loadingRevenueCard = false;
      },
      (err: Error) => {
        this.loadingRevenueCard = false;
        this.loadingRevenueCardFailed = true;
        this.logger.error('GET revenue report error', err);
      }
    );
  }

  public refreshChargedRevenueCard(): void {
    this.loadingChargedRevenueCard = true;
    this.loadingChargedRevenueCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.REVENUE_BY_CHARGE;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalChargedRevenue = res.totalRevenue;
        this.maChargedRevenue = res.maRevenue;
        this.byoaChargedRevenue = res.byoaRevenue;
        this.loadingChargedRevenueCard = false;
      },
      (err: Error) => {
        this.loadingChargedRevenueCard = false;
        this.loadingChargedRevenueCardFailed = true;
        this.logger.error('GET revenue report error', err);
      }
    );
  }

  public refreshGreenShipmentsCard(): void {
    this.loadingGreenShipmentsCard = true;
    this.loadingGreenShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.GREEN_SHIPMENTS_REVENUE;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.greenShipments = formatNumber(res.greenShipments, DEFAULT_LOCALE);
        this.greenRevenue = res.greenRevenue;
        this.loadingGreenShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingGreenShipmentsCard = false;
        this.loadingGreenShipmentsCardFailed = true;
        this.logger.error('GET green shipments report error', err);
      }
    );
  }

  public refreshStripeCard(): void {
    this.loadingStripeCard = true;
    this.loadingStripeCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.STRIPE;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.stripeRevenue = res.stripeRevenue;
        this.loadingStripeCard = false;
      },
      (err: Error) => {
        this.loadingStripeCard = false;
        this.loadingStripeCardFailed = true;
        this.logger.error('GET stripe report error', err);
      }
    );
  }

  public refreshReconAdjustmentsCard(): void {
    this.loadingReconAdjustmentsCard = true;
    this.loadingReconAdjustmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.RECON_ADJUSTMENTS;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.stripeReconAdjustment = res.stripeReconAdjustment;
        this.maReconAdjustment = res.maReconAdjustment;
        this.reconAdjustment = res.reconAdjustment;
        this.loadingReconAdjustmentsCard = false;
      },
      (err: Error) => {
        this.loadingReconAdjustmentsCard = false;
        this.loadingReconAdjustmentsCardFailed = true;
        this.logger.error('GET recon adjustments report error', err);
      }
    );
  }

  public refreshCQCDRevenueCard(): void {
    this.loadingCQCDRevenueCard = true;
    this.loadingCQCDRevenueCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.CQCD;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalCQCDRevenue = res.totalRevenue;
        this.maCQCDRevenue = res.maRevenue;
        this.byoaCQCDRevenue = res.byoaRevenue;
        this.loadingCQCDRevenueCard = false;
      },
      (err: Error) => {
        this.loadingCQCDRevenueCard = false;
        this.loadingCQCDRevenueCardFailed = true;
        this.logger.error('GET CQCD revenue report error', err);
      }
    );
  }

  public refreshPDCRevenueCard(): void {
    this.loadingPDCRevenueCard = true;
    this.loadingPDCRevenueCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.PANIER_DA_CHAT;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalPDCRevenue = res.totalRevenue;
        this.maPDCRevenue = res.maRevenue;
        this.byoaPDCRevenue = res.byoaRevenue;
        this.loadingPDCRevenueCard = false;
      },
      (err: Error) => {
        this.loadingPDCRevenueCard = false;
        this.loadingPDCRevenueCardFailed = true;
        this.logger.error('GET PDC revenue report error', err);
      }
    );
  }

  public refreshWalletBalanceCard(): void {
    this.loadingWalletBalanceCard = true;
    this.loadingWalletBalanceCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.WALLET_BALANCE;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalWalletBalance = res.walletBalance || 0;
        this.totalWalletAccounts = res.walletAccounts || 0;
        this.loadingWalletBalanceCard = false;
      },
      (err: Error) => {
        this.loadingWalletBalanceCard = false;
        this.loadingWalletBalanceCardFailed = true;
        this.logger.error('GET Wallet Balance report error', err);
      }
    );
  }

  // SHIPMENT CARDS
  public refreshAllShipmentsCard(): void {
    this.loadingAllShipmentsCard = true;
    this.loadingAllShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.SHIPMENTS;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalShipments = formatNumber(res.totalShipments, DEFAULT_LOCALE);
        this.maShipments = formatNumber(res.maShipments, DEFAULT_LOCALE);
        this.byoaShipments = formatNumber(res.byoaShipments, DEFAULT_LOCALE);
        this.loadingAllShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingAllShipmentsCard = false;
        this.loadingAllShipmentsCardFailed = true;
        this.logger.error('GET all shipments report error', err);
      }
    );
  }

  public refreshManualShipmentsCard(): void {
    this.loadingManualShipmentsCard = true;
    this.loadingManualShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.SHIPMENTS_MANUAL;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalManualShipments = formatNumber(res.totalShipments, DEFAULT_LOCALE);
        this.manualMaShipments = formatNumber(res.maShipments, DEFAULT_LOCALE);
        this.manualByoaShipments = formatNumber(res.byoaShipments, DEFAULT_LOCALE);
        this.loadingManualShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingManualShipmentsCard = false;
        this.loadingManualShipmentsCardFailed = true;
        this.logger.error('GET all shipments report error', err);
      }
    );
  }

  public refreshEcommShipmentsCard(): void {
    this.loadingEcommShipmentsCard = true;
    this.loadingEcommShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.SHIPMENTS_ECOMMERCE;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalEcommerceShipments = formatNumber(res.totalShipments, DEFAULT_LOCALE);
        this.ecommerceMaShipments = formatNumber(res.maShipments, DEFAULT_LOCALE);
        this.ecommerceByoaShipments = formatNumber(res.byoaShipments, DEFAULT_LOCALE);
        this.loadingEcommShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingEcommShipmentsCard = false;
        this.loadingEcommShipmentsCardFailed = true;
        this.logger.error('GET ecommerce shipments report error', err);
      }
    );
  }

  public refreshApiShipmentsCard(): void {
    this.loadingApiShipmentsCard = true;
    this.loadingApiShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.SHIPMENTS_API;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalApiShipments = formatNumber(res.totalShipments, DEFAULT_LOCALE);
        this.apiMaShipments = formatNumber(res.maShipments, DEFAULT_LOCALE);
        this.apiByoaShipments = formatNumber(res.byoaShipments, DEFAULT_LOCALE);
        this.loadingApiShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingApiShipmentsCard = false;
        this.loadingApiShipmentsCardFailed = true;
        this.logger.error('GET API shipments report error', err);
      }
    );
  }

  public refreshConnectShipmentsCard(): void {
    this.loadingConnectShipmentsCard = true;
    this.loadingConnectShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.SHIPMENTS_CONNECT;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalConnectShipments = formatNumber(res.totalShipments, DEFAULT_LOCALE);
        this.connectMaShipments = formatNumber(res.maShipments, DEFAULT_LOCALE);
        this.connectByoaShipments = formatNumber(res.byoaShipments, DEFAULT_LOCALE);
        this.loadingConnectShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingConnectShipmentsCard = false;
        this.loadingConnectShipmentsCardFailed = true;
        this.logger.error('GET connect shipments report error', err);
      }
    );
  }

  // INTERNAL HELPER FUNCTIONS
  private createForm() {
    // Create the base form
    this.filterForm = this.formBuilder.group({
      provinces: ['', []],
      couriers: ['', []],
      accountTypes: ['', []],
    });

    // Populate the couriers dropdown
    const allProviders: any[] = this.providersService.getProvidersList();
    allProviders.forEach((provider) => {
      if ([ShippingProviders.RIVO].includes(provider.enum)) {
        this.couriersDropdown.push({
          id: provider.enum,
          text: this.helpersService.convertShipmentProviderToString(provider.enum, undefined, true),
        });
      } else if (![ShippingProviders.INTELCOM].includes(provider.enum)) {
        this.couriersDropdown.push({
          id: provider.enum,
          text: provider.name,
        });
      }
    });
    this.providersService.getFreightProvidersList().forEach((provider) => {
      this.couriersDropdown.push({ id: provider.enum, text: provider.name });
    });
    // Sort the couriers alphabetically
    this.couriersDropdown.sort((a, b) => (a.text < b.text ? -1 : 1));
  }

  private getFilterQuery(): any {
    const query = {
      start_date: DateTime.fromJSDate(this.range.value.start).startOf('day').toUTC().toISO({ includeOffset: false }),
      end_date: DateTime.fromJSDate(this.range.value.end).endOf('day').toUTC().toISO({ includeOffset: false }),
      report_card_group: undefined,
    };

    if (!_.isEmpty(this.filterForm.value.provinces) && _.isArray(this.filterForm.value.provinces)) {
      query['province'] = this.filterForm.value.provinces;
    }

    if (!_.isEmpty(this.filterForm.value.couriers) && _.isArray(this.filterForm.value.couriers)) {
      query['provider'] = this.filterForm.value.couriers;
    }

    if (!_.isEmpty(this.filterForm.value.accountTypes) && _.isArray(this.filterForm.value.accountTypes)) {
      query['account_type'] = this.filterForm.value.accountTypes;
    }

    return query;
  }
}
