import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { LoggerService } from '@app/shared/services/logger.service';
import * as _ from 'lodash';
import { DashboardCard, DashboardService } from '@app/shared/services/dashboard.service';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-modify-card',
  templateUrl: './modify-card.component.html',
})
export class ModifyCardComponent implements OnInit {
  public isEditing: boolean;
  public canDelete: boolean = true;
  public loading: boolean = true;
  public maxInputLen: number = 200;
  public cannotDeleteMessage?: string = undefined;
  public card: DashboardCard;
  public cardForm: FormGroup;

  constructor(
    public drawerRef: MtxDrawerRef<ModifyCardComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      card?: DashboardCard;
      isDefaultCard?: boolean;
      cardIsUsed?: boolean;
    },
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loading = true;
    this.card = this.data.card;
    this.isEditing = !!this.card;
    this.canDelete = !this.data.isDefaultCard && !this.data.cardIsUsed;
    if (this.data.isDefaultCard) {
      this.cannotDeleteMessage = 'Cannot delete a default card';
    } else if (this.data.cardIsUsed) {
      this.cannotDeleteMessage = 'Cannot delete a card saved to a dashboard';
    }

    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(newCard?: DashboardCard): void {
    this.drawerRef.dismiss(newCard);
  }

  public saveCard() {
    if (!this.loading && !this.cardForm.invalid) {
      this.loading = true;
      if (!this.isEditing || this.cardForm.value.saveAsNew) {
        this.createCard();
      } else {
        this.updateCard();
      }
    }
  }

  public createCard(): void {
    const createCardSDto: any = this.cardForm.value;
    this.dashboardService.createCard(createCardSDto).subscribe(
      (newCard: DashboardCard) => {
        this.bannerNotificationsService.success('Card was saved successfully');
        this.logger.log('Create dashboard card success', newCard);
        this.dismissDrawer(newCard);
      },
      (err) => {
        this.bannerNotificationsService.error('Saving the card failed', {
          category: 'Create dashboard card error',
          message: err,
        });
        this.dismissDrawer();
      }
    );
  }

  public updateCard(): void {
    const updateCardSDto: any = this.cardForm.value;
    this.dashboardService.updateCard(this.card.id, updateCardSDto).subscribe(
      (newCard: DashboardCard) => {
        this.bannerNotificationsService.success('Card was saved successfully');
        this.logger.log('Update dashboard card success', newCard);
        this.dismissDrawer(newCard);
      },
      (err) => {
        this.bannerNotificationsService.error('Saving the card failed', {
          category: 'Update dashboard card error',
          message: err,
        });
        this.dismissDrawer();
      }
    );
  }

  public deleteCard(): void {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      data: {
        title: `Delete card: ${this.card.name}?`,
        content: `Are you sure you want to delete this card?`,
        confirmBtnLabel: 'Delete',
        btnColor: 'warn',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dashboardService.deleteCard(this.card.id).subscribe(
          (res: any): void => {
            this.bannerNotificationsService.success('Card was saved deleted');
            this.logger.log('Delete dashboard card success', res);
            this.dismissDrawer(this.card);
          },
          (err) => {
            this.bannerNotificationsService.error(
              'Deleting the card failed. You cannot delete a card that is used in a dashboard.',
              {
                category: 'Delete dashboard card error',
                message: err,
              }
            );
            this.dismissDrawer();
          }
        );
      }
    });
  }

  private setupForm(): void {
    this.cardForm = this.formBuilder.group({
      name: [_.get(this.card, 'name', ''), [Validators.required]],
      imageUrl: [_.get(this.card, 'imageUrl', ''), [Validators.required]],
      title: [_.get(this.card, 'title', ''), [Validators.required]],
      body: [_.get(this.card, 'body', ''), [Validators.maxLength(this.maxInputLen), Validators.required]],
      buttonLabel: [_.get(this.card, 'buttonLabel', ''), []],
      buttonUrl: [_.get(this.card, 'buttonUrl', ''), []],
      saveAsNew: [],
    });
  }

  protected readonly isSecureContext = isSecureContext;
}
