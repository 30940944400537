<ng-container>
  <form [formGroup]="searchForm">
    <mat-card [ngClass]="{'mb--sm': paginatedUnknownShipments.length == 0 }">
      <mat-card-content class='pb--none'>
        <div class='row'>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Courier</mat-label>
              <mat-select formControlName="provider" name='providerItem'>
                <mat-option *ngFor='let providerItem of providerItems' value="{{ providerItem.id }}">{{ providerItem.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Tracking number #</mat-label>
              <input matInput formControlName="trackingNumber" name="trackingNumber" type="text" maxlength="30">
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4 align-right mt--xs'>
            <button mat-button color='primary' type='button' (click)='reset()' [disabled]="searchForm.invalid || loading" class='mr--md' >Clear all</button>
            <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <ng-container *ngIf='!loading, else isLoading' class="mt--lg">
    <ng-container *ngIf="!loading && paginatedUnknownShipments.length > 0, else noUnknownTracking">
      <mat-card class='mt--xs mat-elevation-z0 mb--sm'>
        <mat-card-content>
          <div class='responsive-table'>
            <table mat-table [dataSource]="unknownShipmentsDataSet" multiTemplateDataRows matSort (matSortChange)="sortData($event)">
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef style='width: 10%' mat-sort-header sortActionDescription="Sort by date">Date</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.createdAt | date: 'E, d MMM' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="unknownType">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'>Unknown Type</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <div class='pill-box pill-status' [ngClass]="getBadgeClass(element?.data?.providerInvoiceItem)">{{ getStatusText(element?.data?.providerInvoiceItem) }}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef style='width: 15%' mat-sort-header sortActionDescription="Sort by provider">Provider</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <div *ngIf="!isInsuranceProvider(element.provider)">
                      <img
                        [src]="getProviderImage(element.provider)"
                        width="25px"
                        height="25px"
                        [mtxTooltip]="getProviderName(element.provider)"
                        mtxTooltipPosition="above"
                        mtxTooltipClass="mtx-recon-tooltip"/>
                  </div>
                  <div *ngIf="isInsuranceProvider(element.provider)">
                    <mat-icon class='icon-info mat-icon-sm'
                    [mtxTooltip]="element.provider"
                    mtxTooltipPosition="above"
                    mtxTooltipClass="mtx-recon-tooltip"></mat-icon>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="trackingNumber">
                <th mat-header-cell *matHeaderCellDef style='width: 20%'>Tracking</th>
                <td mat-cell *matCellDef="let element" class='text--sm cursor-pointer' (click)="copyText(element.trackingNumber)">
                  {{ element.trackingNumber }}
                </td>
              </ng-container>
              <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef style='width: 20%'>Reference</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <a class='text--primary-green cursor-pointer' (click)="openEdiInvoiceDrawer(element)">{{ getTrackingReference(element, 'More Info') }}</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="cost">
                <th mat-header-cell *matHeaderCellDef style='width: 10%' mat-sort-header sortActionDescription="Sort by cost">Cost</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.totalAmount | currency : 'CAD' : 'symbol-narrow' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style='width: 10%'></th>
                <td mat-cell *matCellDef="let element" class='text--sm align-right'>
                  <ng-container>
                    <button mat-icon-button [matMenuTriggerFor]="unknownMenu" aria-label="Unknown actions" color='primary' [disabled]="!canEdit">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #unknownMenu="matMenu">
                      <button mat-menu-item (click)="assignUnknownTrackingNumber(element)" [disabled]="isInsuranceProvider(element.provider)">
                        <span>Assign to existing shipment</span>
                      </button>
                      <button mat-menu-item class='border-top' (click)="ignoreUnknownTrackingNumber(element)">
                        <span class="text--danger">Ignore</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            (page)="pageChanged($event)"
            [length]='unknownShipmentsCount'
            [pageSize]="itemsPerPage"
            [pageSizeOptions]="itemsPerPageOptions"
            [pageIndex]='currentPage'>
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #noUnknownTracking>
      <div class='row justify-content-center'>
        <div class='column--12 align-center'>
          <div style='width: 220px; margin: 0 auto;'>
            <img src='{{ CDN_URL }}/admin/images/empty-history_animated.svg' />
            <p>No Unknown Shipments available.</p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
  </ng-template>
</ng-container>