import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DateFormats,
  EDI_INVOICE_MODAL_TYPE,
  LegacyShipmentStatuses,
  PanelWidths,
  ShipmentStatuses,
  ShippingProviders,
  TrackingTypes,
} from '@app/shared/constants';
import { ComponentsModule } from '@app/shared/components/components.module';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatListItem, MatListItemTitle, MatNavList } from '@angular/material/list';
import { MatTooltip } from '@angular/material/tooltip';
import { CommonModule, CurrencyPipe, NgForOf, NgIf, NgSwitchCase } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import * as _ from 'lodash';
import { HelpersService } from '@app/shared/services/helpers.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import { environment } from '../../../../../environments/environment';
import { ShipmentsService } from '@app/shared/services/shipments.service';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { TrackingComponent } from '@app/pages/authenticated/components/tracking/tracking.component';
import { LoggerService } from '@app/shared/services/logger.service';
import * as FileSaver from 'file-saver';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { ModalCreateReturnLabelComponent } from '@app/pages/authenticated/pages/shipments/components/modal-create-return-label/modal-create-return-label.component';
import { CanadaPostReceiptComponent } from '@app/pages/authenticated/components/canada-post-receipt/canada-post-receipt.component';
import { EdiInvoiceComponent } from '@app/pages/authenticated/components/edi-invoice/edi-invoice.component';
import { AdminChargeDetailsComponent } from '@app/pages/authenticated/components/admin-charge-details/admin-charge-details.component';
import { DateTime } from 'luxon';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { InsuranceService } from '@app/shared/services/insurance.service';
import { NotesComponent } from '@app/pages/authenticated/pages/shipments/components/notes/notes.component';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'shipment-details',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './shipment-details.component.html',
  imports: [
    ComponentsModule,
    CommonModule,
    MatAnchor,
    MatButton,
    MatCard,
    MatCardContent,
    MatDivider,
    MatIcon,
    MatIconButton,
    MatListItem,
    MatListItemTitle,
    MatNavList,
    MatTooltip,
    NgForOf,
    NgIf,
    NgSwitchCase,
    SharedModule,
  ],
})
export class ShipmentDetailsComponent implements OnInit, OnDestroy {
  @Input() withHeader: boolean = false;
  @Input() canAddNote: boolean = false;
  @Input() canCancel: boolean = false;
  @Input() shipment: any;
  @Input() user: AdminUser;

  @Output() refreshShipment: EventEmitter<void> = new EventEmitter();

  public loading: boolean = true;
  public companyName: string;
  public companyId: number;
  public userFirstName: string;
  public userLastName: string;
  public userId: number;
  public trackingNumber: string;
  public rates: any;
  public isDomesticAccShipment: boolean = false;
  public showingTracking: boolean = false;
  public gettingLabel: boolean = false;
  public gettingMARates: boolean = false;
  public gettingCertificate: boolean = false;
  public canEdit: boolean = false;
  public canViewShipmentDetails: boolean = false;
  public canViewUsers: boolean = false;
  public canViewRecon: boolean = false;
  public canViewManifests: boolean = false;
  public canCreateReturns: boolean = false;
  public noReferences = true;
  public additionalDetails: any = {
    reference1: '',
    reference2: '',
    additionalNote: '',
  };

  protected readonly DATE_YEAR: string = DateFormats.DATE_YEAR;
  protected readonly DATE_TIME_YEAR: string = DateFormats.DATE_TIME_SHIPMENT;
  protected readonly CDN_URL: string = environment.CDN_URL;
  protected readonly shippingProviders = ShippingProviders;
  protected readonly shipmentStatuses = ShipmentStatuses;

  private currencyPipe = new CurrencyPipe('en');

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private bannerNotificationsService: BannerNotificationsService,
    private userService: UserService,
    private shipmentsService: ShipmentsService,
    private billingInvoicesService: BillingInvoicesService,
    private insuranceService: InsuranceService,
    private providersService: ProvidersService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    if (this.user) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_SHIPMENTS]);
      this.canViewUsers = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS]);
      this.canViewRecon = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_RECONCILIATION]);
      this.canViewManifests = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_MANIFESTS]);
      this.canCreateReturns = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_SHIPMENTS_RETURN_LABEL]);

      this.canViewShipmentDetails = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_SHIPMENTS_DETAILS]);
    }
    if (this.shipment) {
      this.setup(this.shipment);
    }
  }

  ngOnDestroy() {
    if (this.drawer) {
      this.drawer.dismiss();
    }
    if (this.dialog) {
      this.dialog.closeAll();
    }
  }

  public goToUsersTab(): void {
    if (this.canViewUsers) {
      this.sessionStorageService.store('csSearchParams', {
        filters: {
          companyName: this.companyName,
          firstName: this.userFirstName,
          lastName: this.userLastName,
        },
        page: 1,
      });
      this.router.navigate(['users']);
    }
  }

  // TODO - Hook this up?
  public goToReconTab(shipment: any): void {
    // this.tabChangeRequested.emit({
    //   tab: 'recon',
    //   trackingNumber: shipment.tracking,
    //   status: ShipmentChargeStatus.ADJUSTMENT_APPROVED,
    //   companyOwnerName: this.companyName,
    //   companyId: this.companyId,
    //   fromDate: moment(shipment.createdAt).subtract(1, 'month').valueOf(),
    //   toDate: moment(shipment.createdAt).add(1, 'month').valueOf(),
    // });
  }

  public copyText(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.width = '0';
    textarea.style.height = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.bannerNotificationsService.success('Tracking number copied to clipboard');
  }

  public openTracking(shipment: any, type?: string): void {
    if (shipment.provider === ShippingProviders.LIVRAPIDE) {
      window.open(`https://plus.shiptrackapp.com/view.aspx?lng=&tracking=${shipment.tracking}`);
      return;
    }
    return this.openTrackingModal(shipment, type);
  }

  private openTrackingModal(shipment: any, type?: string): void {
    if (!this.showingTracking) {
      this.showingTracking = true;
      let trackingNumber = shipment.tracking;

      if (type === TrackingTypes.RETURN) {
        trackingNumber = _.get(shipment, 'returnTrackings[0]');
      }
      const drawerRef = this.drawer.open(TrackingComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: {
          shipment: this.shipment,
          trackingNumber,
          company: this.shipment.companyAndUserInfo.company,
        },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        this.showingTracking = false;
      });
    }
  }

  public createReturnLabel(shipment: any) {
    if (this.canCreateReturns && this.isDomesticAccShipment) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '600px',
        data: {
          title: 'Create return label',
          content: `Are you sure you want to create a return label?`,
          btnColor: 'primary',
          confirmBtnLabel: 'Create return label',
        },
      });
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          // Swap sender
          const sender = shipment.recipientInfo;
          // If missing email
          if (!sender.email || sender.email === '') {
            sender.email = this.shipment.companyAndUserInfo.createdBy.email;
          }
          // If missing phone number, fallback with origin sender/company phone
          if (!sender.phone || sender.phone === '') {
            sender.phone = shipment.senderInfo.phone || this.shipment.companyAndUserInfo.company.phone;
          }
          const selectedService =
            _.isObject(shipment.service) && _.get(shipment, 'service.code') ? shipment.service : shipment.serviceObj;

          // Swap recipient
          const recipient = shipment.senderInfo;

          const createReturnShipmentDto = this.shipmentsService.buildShipmentDTO(
            sender,
            recipient,
            shipment.items,
            undefined,
            selectedService
          );

          // Extend parent shipment for return label
          _.extend(createReturnShipmentDto, {
            parentShipment: shipment,
            isCreateReturnShipment: true,
            accountType: shipment?.accountType || _.get(selectedService, 'type'),
            isMultiLeg: shipment.provider === ShippingProviders.RIVO,
            companyAndUserInfo: _.get(shipment, 'companyAndUserInfo'),
          });

          const returnDialogRef = this.dialog.open(ModalCreateReturnLabelComponent, {
            width: '600px',
            data: {
              shipment,
              createReturnShipmentDto: createReturnShipmentDto,
            },
          });
          returnDialogRef.afterClosed().subscribe((successfulResult) => {
            this.refreshShipment.emit();
          });
        }
      });
    }
  }

  public setStatus(status: ShipmentStatuses) {
    if (this.canEdit) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '600px',
        data: {
          title: 'Change internal status',
          content: `This will change shipment status to CL (Cancelled), and shipment charges statuses to RA (Refund approved). Actual Refunds still need to be handled manually.`,
          btnColor: 'warn',
          confirmBtnLabel: 'Change status to CANCELLED',
        },
      });
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.shipmentsService.update(this.shipment.id, { status }).subscribe(
            (res: any) => {
              this.bannerNotificationsService.success('Shipment status successfully updated.', {
                category: 'SET shipment status',
                message: res,
              });
              this.refreshShipment.emit();
            },
            (err: Error) => {
              this.refreshShipment.emit();
              this.bannerNotificationsService.error('Shipment status update failed.', {
                category: 'SET shipment status',
                message: err,
              });
            }
          );
        }
      });
    }
  }

  public addInternalNote(): void {
    const drawerRef = this.drawer.open(NotesComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment: this.shipment, adminUser: this.user },
    });
    drawerRef.afterDismissed().subscribe((successfulResult) => {
      if (successfulResult) {
        this.refreshShipment.emit();
      }
    });
  }

  public openCanadaPostReceiptModal(shipment) {
    this.drawer.open(CanadaPostReceiptComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment },
    });
  }

  public openEdiInvoiceModal(shipment) {
    this.drawer.open(EdiInvoiceComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment, type: EDI_INVOICE_MODAL_TYPE.EDI_INVOICE_ONLY },
    });
  }

  public openAdminChargeDetailsModal(shipment) {
    this.drawer.open(AdminChargeDetailsComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment: this.shipment },
    });
  }

  public openInvoiceChargeDetailsModal(invoice) {
    this.billingInvoicesService.getInvoicePdf({ invoiceNumber: invoice.invoiceNumber }).subscribe(
      (res: any) => {
        const mimeType = 'application/pdf';
        let downloadFileName = 'download.pdf';

        if (invoice.trackingNumber) {
          downloadFileName = `machool-billing-statement-${invoice.trackingNumber}.pdf`;
          // invoice by date range
        } else {
          const startDate = DateTime.fromISO(invoice.dateRangeStart).toISODate();
          const endDate = DateTime.fromISO(invoice.dateRangeEnd).toISODate();
          downloadFileName = `machool-billing-statement-${startDate}-${endDate}.pdf`;
        }

        if (res.errors.length === 0) {
          const blob = this.helpersService.b64toBlob(res.data, mimeType);
          FileSaver.saveAs(blob, downloadFileName);
          this.gettingLabel = false;
        } else {
          this.bannerNotificationsService.error('Could not get shipment invoice', {
            category: 'GET Billing invoices PDF error',
            message: {
              errors: res.errors,
            },
          });
          this.gettingLabel = false;
        }
      },
      (err: Error) => {
        this.logger.error('GET Billing invoices PDF', err);
      }
    );
  }

  public getThirdPartyInsuranceCertificate(shipment: any) {
    if (!this.gettingCertificate) {
      this.gettingCertificate = true;
      const params = {
        certificateNumber: shipment.thirdPartyInsurance.certificateNumber,
        securityId: shipment.thirdPartyInsurance.securityId,
      };
      this.insuranceService.getCertificate(params).subscribe(
        (res: any) => {
          const mimeType = 'application/pdf';
          const downloadFileName = `machool-insurance-${shipment.thirdPartyInsurance.certificateNumber}.pdf`;
          const blob = this.helpersService.b64toBlob(res.data, mimeType);
          FileSaver.saveAs(blob, downloadFileName);
          this.gettingCertificate = false;
        },
        (err: Error) => {
          this.gettingCertificate = false;
          this.logger.error('GET insurance certificate error', err);
        }
      );
    }
  }

  public getDocuments(shipment: any, type: string) {
    if (!this.gettingLabel) {
      this.gettingLabel = true;
      const shipmentProvider =
        shipment.isMultiLeg && type === 'manifests' && shipment.manifest
          ? shipment.shipmentLegs[0].provider
          : shipment.provider;
      const query = {
        provider: shipmentProvider,
        type: type,
        id: shipment.shipment,
        uuid: shipment.orderId,
      };

      if (type === 'manifests' && shipment.manifest) {
        query.id = shipment.manifest.id;
        _.extend(query, { manifestNumber: shipment.manifest.manifestNumber });
      }

      if (type === 'cod-return-labels') {
        query.id = shipment.codReturn;
      }

      if (
        type === 'return-labels' &&
        [ShippingProviders.PUROLATOR, ShippingProviders.ICS_COURIER].includes(shipment.provider)
      ) {
        query.id = shipment.returnTrackings[0];
      }
      this.shipmentsService.getDocuments(query).subscribe(
        (res: any) => {
          this.logger.log('GET Shipment documents', res);

          let mimeType = 'application/pdf';
          let downloadFileName = 'download.pdf';
          const providerName = shipment.provider;
          const trackingNumber = shipment.tracking || '';

          if (type === 'high-value-reports') {
            mimeType = 'text/html';
            downloadFileName = 'download.html';
          }

          if (providerName && trackingNumber) {
            switch (type) {
              case 'labels':
                downloadFileName = `${providerName}-${trackingNumber}-label.pdf`;
                break;
              case 'commercial-invoices':
                downloadFileName = `${providerName}-${trackingNumber}-customs.pdf`;
                break;
              case 'return-labels':
                downloadFileName = `${providerName}-${trackingNumber}-return-label.pdf`;
                break;
              case 'cod-receipts':
                downloadFileName = `${providerName}-${trackingNumber}-cod-receipt.pdf`;
                break;
              case 'manifests':
                downloadFileName = `${shipment.group}-manifest.pdf`;
                break;
              case 'cod-return-labels':
                downloadFileName = `${providerName}-${trackingNumber}-cod-return-label.pdf`;
                break;
            }
          }

          if (res.errors.length === 0) {
            const blob = this.helpersService.b64toBlob(res.data, mimeType);
            FileSaver.saveAs(blob, downloadFileName);
            this.gettingLabel = false;
          } else {
            this.bannerNotificationsService.error('Could not get shipment document', {
              category: 'GET shipment document error',
              message: {
                errors: res.errors,
              },
            });
            this.gettingLabel = false;
          }
        },
        (err: any) => {
          this.bannerNotificationsService.error('Could not get shipment document', {
            category: 'GET shipment document error',
            message: err,
          });
          this.gettingLabel = false;
        }
      );
    }
  }

  public getEcommerceText(shipment: any) {
    const provider = _.get(shipment, 'ecommerceShipment.provider');
    const storeName = _.get(provider, 'configuration.name');
    const storeType = this.helpersService.convertEcommerceProviderToString(provider.type);
    return `Created from ${storeType} store: ${storeName}`;
  }

  public getProviderImage(provider, service?: any): string {
    return this.providersService.getProviderSmallLogo(provider, service);
  }

  public getProviderLogoSrc(providerCode: string): string {
    // TODO: Legacy provider. Remove once old shipment data archived (4266)
    if (providerCode === ShippingProviders.COURANT_PLUS) {
      providerCode = 'courantplus-nationex';
    }
    return `${this.CDN_URL}/shared/images/couriers/${providerCode}.svg`;
  }

  public getProviderCode(service) {
    const serviceProvider = service.provider;
    let serviceName = '';
    if (service.name) {
      serviceName = service.name.toLowerCase();
    }
    if (serviceName && serviceName.includes('fedex') && serviceName.includes('ground')) {
      return 'fedex-ground';
    } else if (
      serviceName &&
      serviceName.includes('fedex') &&
      (serviceName.includes('express') ||
        serviceName.includes('overnight') ||
        serviceName.includes('economy') ||
        serviceName.includes('2day'))
    ) {
      return 'fedex-express';
      // TODO: AC - Revisit when we want to replace completely Rivo with AirCanada
    } else if (serviceProvider === ShippingProviders.RIVO) {
      if ((service.code || '').toUpperCase().includes('AC') || (service.name || '').toUpperCase().includes('MULTI')) {
        return this.providersService.getAirCanadaImageByServiceName(serviceName);
      } else {
        return serviceProvider ? serviceProvider : null;
      }
    } else {
      return serviceProvider ? serviceProvider : null;
    }
  }

  public showSection(rate): boolean {
    // There are some surcharges or options that are included with amount 0,
    // So we as long as we have something in their list we should display them,
    // for other rates we should just make sure the amount is not 0
    if (rate.label === 'surcharges' || rate.label === 'options') {
      return rate.list && rate.list.length > 0;
    } else {
      return rate.amount !== 0 || (rate.amount === 0 && _.get(rate, 'valueIncluded', false));
    }
  }

  public showAmount(rate): boolean {
    return (
      rate.label !== 'options' &&
      rate.label !== 'surcharges' &&
      rate.label !== 'taxes' &&
      rate.label !== 'total' &&
      rate.label !== 'discount' &&
      rate.amount > 0
    );
  }

  public getRateValue(value) {
    // Hide rate value if this is a shipment being billed to a third party
    if (!_.isEmpty(this.shipment.service.thirdParty)) {
      return '-';
    } else {
      const returnValue = _.isString(value) ? value : this.currencyPipe.transform(value, null, 'symbol', '1.2-2');
      return !returnValue || _.isEmpty(returnValue) || _.isNull(returnValue) ? 'Included' : returnValue;
    }
  }

  public getTaxRatesLabel(rate) {
    if (!rate.name && rate.code && rate.code.length > 0) {
      if (this.isQstOrPstTax(rate.code.toLowerCase())) {
        return 'QST';
      } else {
        return rate.code.toUpperCase();
      }
    } else {
      if (this.isQstOrPstTax(rate.name.toLowerCase())) {
        return 'QST';
      } else {
        return rate.name.toUpperCase();
      }
    }
  }

  public getReceipt(receiptUrl: string) {
    if (!receiptUrl) return;
    window.open(
      receiptUrl,
      'targetWindow',
      `
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=700,
      height=900`
    );
  }

  private isQstOrPstTax(taxCode): boolean {
    return taxCode.includes('qst') || taxCode.includes('pst');
  }

  private surchargeCodeOrNameIncludesFuel = (surcharge: any) => {
    const { code, name } = surcharge;

    if (code && _.isString(code) && code.toLowerCase().includes('fuel')) {
      return true;
    }

    if (name && _.isString(name) && name.toLowerCase().includes('fuel')) {
      return true;
    }

    return false;
  };

  private getPhoneNumber(addressInfo): string {
    const phone = _.get(addressInfo, 'phone.phoneNumber', _.get(addressInfo, 'phone', ''));
    const phoneExtension = _.get(addressInfo, 'phone.extension', _.get(addressInfo, 'phoneExtension', ''));

    return phoneExtension && phoneExtension.length > 0 ? `${phone} x${phoneExtension}` : phone;
  }

  private hasOptions(rates) {
    let hasOptions = false;

    for (const rate of rates) {
      if (rate.label === 'options' && rate.list && rate.list.length > 0) {
        hasOptions = true;
      }
    }

    return hasOptions;
  }

  private filterRates() {
    if (!_.isEmpty(this.shipment.service.thirdParty)) {
      _.remove(this.rates, { label: 'regular price' });
      _.remove(this.rates, { label: 'taxes' });
      _.remove(this.rates, { label: 'total' });
      _.remove(this.rates, { label: 'discount' });

      const surchargeIndex = _.findIndex(this.rates, { label: 'surcharges' });

      if (surchargeIndex) {
        _.remove(this.rates[surchargeIndex].list, this.surchargeCodeOrNameIncludesFuel);
      }
    }

    // Remove null discounts
    _.remove(this.rates, { label: 'discount', amount: null });
  }

  public getPackageDetailsString(parcel: any, shipment: any): string {
    let baseDimsString = `${parcel.length}${parcel.linearUnits} × ${parcel.width}${parcel.linearUnits} × ${parcel.height}${parcel.linearUnits}`;

    const isFreightACCShipment = shipment.provider === ShippingProviders.FREIGHT_AIR_CANADA;

    if (isFreightACCShipment) {
      const freightBaseString = `${parcel.quantity} piece${parcel.quantity > 1 ? 's' : ''} of ${baseDimsString}`;
      baseDimsString = !shipment.hasAdjustment
        ? `${freightBaseString}, ${parcel.weight}${parcel.weightUnits}`
        : `${freightBaseString}, total adjusted weight ${shipment.adjustedWeight}${shipment.adjustedUnits} (declared weight ${parcel.weight}${parcel.weightUnits})`;
    } else {
      baseDimsString = !shipment.hasAdjustment
        ? `${baseDimsString}, ${parcel.weight}${parcel.weightUnits}`
        : `${baseDimsString}, ${shipment.adjustedWeight}${shipment.adjustedUnits} (declared weight ${parcel.weight}${parcel.weightUnits})`;
    }
    return baseDimsString;
  }

  private setup(shipment): void {
    this.shipment = shipment;
    this.shipment.serviceObj = _.extend(this.shipment.serviceObj, {
      provider: this.shipment.provider,
      name: this.shipment.service.name || this.shipment.service,
      code: this.shipment.service.code ? String(this.shipment.service.code) : undefined,
      type: this.shipment.accountType,
      thirdParty: this.shipment.service.thirdParty || this.shipment.thirdParty || false,
    });
    this.shipment.service = this.shipment.serviceObj;
    this.shipment.providerImage = shipment.isMultiLeg
      ? this.getProviderImage(shipment.shipmentLegs[0].provider, shipment.service)
      : this.getProviderImage(shipment.provider, shipment.service);
    this.shipment.providerName = shipment.isMultiLeg
      ? this.helpersService.convertShipmentProviderToString(shipment.shipmentLegs[0].provider)
      : this.helpersService.convertShipmentProviderToString(shipment.provider);
    this.shipment.providerCode = this.getProviderCode(shipment.service);
    this.shipment.statusVerbose = this.helpersService.convertLegacyShipmentStatusEnumToString(shipment.status);
    this.shipment.trackingStatusVerbose = shipment.trackingStatus
      ? this.helpersService.convertLegacyShipmentStatusEnumToString(shipment.trackingStatus)
      : 'Not set';
    this.shipment.providerLogoSrc = this.getProviderLogoSrc(this.shipment.providerCode);
    this.shipment.hasBeenCancelled =
      this.shipment.status === LegacyShipmentStatuses.CANCELLED ||
      this.shipment.status === LegacyShipmentStatuses.PENDING_CANCEL;
    this.shipment.hasReturnLabel = this.shipmentsService.isPrintableReturnLabelAvailable(this.shipment);
    this.shipment.hasThirdPartyInsurance =
      this.shipment.rates.thirdPartyInsurance && !_.isEmpty(this.shipment.rates.thirdPartyInsurance);
    this.shipment.isCPNC = shipment.provider === ShippingProviders.CANADA_POST && _.includes(shipment.group, 'NC_');
    this.shipment.senderInfo.fullPhone = this.getPhoneNumber(this.shipment.senderInfo);
    this.shipment.senderInfo.formattedCostCenter = _.get(
      this.shipment.senderInfo.costCenter,
      'name',
      this.shipment.senderInfo.costCenter
    );
    this.shipment.recipientInfo.buzzer = _.get(this.shipment, 'recipientInfo.address.additionalInfo');
    this.shipment.recipientInfo.fullPhone = this.getPhoneNumber(this.shipment.recipientInfo);
    this.isDomesticAccShipment =
      this.shipment.provider === ShippingProviders.RIVO &&
      (this.shipment.recipientInfo.address.country || '').toUpperCase().includes('CA');

    this.shipment.items.forEach((parcel) => {
      parcel.detailDescription = this.getPackageDetailsString(parcel, this.shipment);
    });

    this.rates = this.shipmentsService.rateByProvider(
      this.shipment.provider,
      this.shipment.service.name,
      this.shipment.rates || {}
    );
    if (this.rates) {
      this.shipment.hasOptions = this.hasOptions(this.rates);
      this.rates.forEach((rate) => {
        if (!_.get(rate, 'amount') && _.get(rate, 'value')) {
          rate.amount = rate.value;
        }
        if (rate.list && rate.list.length > 0) {
          rate.list.forEach((listItem) => {
            if (!_.get(listItem, 'amount') && _.get(listItem, 'value')) {
              listItem.amount = listItem.value;
            }
          });
        }
      });
      this.filterRates();
      if (this.shipment.rates.adjustmentCharges && this.shipment.rates.adjustmentCharges.length > 0) {
        const metadata = this.shipment.rates.adjustmentCharges.flatMap((adjustmentCharge) => adjustmentCharge.metadata);
        const metadataForMatchingTrackingNumber = metadata.find(
          (m) => m.trackingNumber === this.shipment.rates.trackingNumber
        );

        if (metadataForMatchingTrackingNumber && metadataForMatchingTrackingNumber.adjustedWeight > 0) {
          this.shipment.adjustedWeight = metadataForMatchingTrackingNumber.adjustedWeight;
          this.shipment.adjustedUnits = metadataForMatchingTrackingNumber.adjustedUnits;
          this.shipment.hasAdjustment = true;
        }
      }
    }
    this.additionalDetails = {
      reference1: this.shipment.recipientInfo.reference1,
      reference2: this.shipment.recipientInfo.reference2,
      additionalNote: this.shipment.recipientInfo.additionalNote,
    };

    this.noReferences =
      !this.additionalDetails.reference1 &&
      !this.additionalDetails.reference2 &&
      !this.additionalDetails.additionalNote;

    if (this.withHeader) {
      this.companyId = this.shipment.companyAndUserInfo.company.id;
      this.companyName = this.shipment.companyAndUserInfo.company.name;
      this.userId = this.shipment.companyAndUserInfo.createdBy.id;
      this.userFirstName = this.shipment.companyAndUserInfo.createdBy.firstName;
      this.userLastName = this.shipment.companyAndUserInfo.createdBy.lastName;
    }

    // Make sure we set the legacy imperial boolean for items returned in this shipment
    this.shipment.items.forEach((item) => (item.imperial = item.weightUnits === 'lb'));

    this.loading = false;
  }
}
